@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assets/fonts/Nexa-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}